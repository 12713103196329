import Routes from "./routes";
import 'antd/dist/reset.css';
import './App.css';
import React  from 'react';

function App() {
  return (
    <div>
      <Routes />
    </div> 
  );
}

export default App;
